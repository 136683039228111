@import '../theme-bootstrap';
@import '../base/adaptive-placeholders';

// Calling adaptive placeholder for input.
// code: adplfeature_v1 (used to grep a site for portions of code associated with a given feature).
.adpl {
  input[type='email'],
  input[type='tel'],
  input[type='password'],
  input[type='text'],
  textarea,
  select {
    @include adaptive-placeholder(
      (
        height: map-get($adaptive-placeholder, height),
        margin: map-get($adaptive-placeholder, margin)
      )
    );
    border-color: $color-light-gray;
  }
  .select label {
    display: none;
  }
  fieldset {
    overflow: visible;
    max-width: 100%;
  }
  .form-item {
    position: relative;
    overflow: visible;
  }
  input,
  select,
  .selectBox {
    resize: none;
    outline: none;
    border: 1px solid $color-light-gray;
    border-color: $color-light-gray;
    &:required,
    &.selectBox-required {
      box-shadow: none;
      &.error,
      &:invalid,
      &.selectBox-invalid {
        border-color: $color-error;
        box-shadow: none;
        &:empty,
        &[value=''],
        &:not(:checked),
        &.empty {
          border-color: $color-light-gray;
          &.error,
          &.checkedEmpty,
          &.touched {
            border-color: $color-error;
          }
        }
      }
      &:valid,
      &.selectBox-valid {
        &.error {
          border-color: $color-error;
        }
      }
      &[data-error] {
        border-color: $color-error;
      }
    }
  }
  select {
    &::-ms-expand {
      display: none;
    }
  }
  input[type='button'],
  input[type='submit'] {
    &:valid {
      border-color: $color-light-gray;
    }
  }
  .inline-error {
    position: absolute;
    @include box-shadow(#333 4px 4px 10px 1px);
    background-color: $white;
    @include border-radius(3px);
    padding: 0.4em 0.8em;
  }
  .valid_marker,
  .invalid_marker {
    font-size: 2em;
    position: absolute;
    #{$rdirection}: 30px;
    bottom: 22px;
    background-color: $white;
    display: none;
  }
  .valid_marker {
    color: $color-green;
  }
  .invalid_marker {
    color: $color-error;
  }
  .form-item.select {
    .invalid_marker,
    .valid_marker {
      display: none;
    }
  }
}
