// Colors (http://sachagreif.com/sass-color-variables/)
// Basic Colors - descriptive names
// do not use directly in scss
$white: #fff !default;
$black: #000 !default;
$red: #f00 !default; // Not part of new design direction, left from checkout
$brown: #392720 !default;
$cream: #fbf8f6 !default;
$gray: #a39e9d !default;
$light-gray: #cdcbc9 !default;
$orange: #fc7854 !default;
$dark-brown: #898787 !default;
$light-brown: #868180 !default;
$color-green: #07bb5b !default;
$color-dark-green: #6d8468 !default;
$color-yellow: #edba1e !default;
$light-red: #ff001e !default;
$current-black: #333 !default;
$current-gray: #555 !default;
$current-half-white: #f2f2f2 !default;
$dark-orange: #ca8441 !default;
$purple: #8c86a4 !default;
$light-blue: #6495ed !default;
$dark-red: #ec4d69 !default;
$color-active-red: #ec4d69 !default;
$color-medium-gray: #bbb !default;
$color-medium-white: #eee !default;
$color-darker-gray: #111012 !default;
$color-darkest-brown: #191513 !default;
$color-darker-brown: #372821 !default;
$color-light-red: #d2121f !default;
$color-lightest-red: #f9ecec !default;
$color-dark-red: #b00020 !default;
$color-darkest-gray: #adadad !default;
$color-transparent-gray: #e5e5e5 !default;
$color-grey-text: #5c5a5a !default;
$color-satin-linen: #e7e3db !default;
$color-danube-blue: #5f7cbb;
$color-checkbox-green: #618b1e;
$color-light-blue-fb: #1877f2;
$color-orange-transparent: rgb(255, 253, 252);
$color-light-orange: rgb(238, 159, 87);

// From styleguide PDF
// NOTE: changing in 2020 - see section below - New colors for $styles20 Styleguide updates
$color-mink: $brown; // Mink
$color-cream: $cream; // off-white
$color-brown: $brown; // Mink is the new Brown
$color-black: $brown; // Mink is the new Black
$color-white: $cream; // Cream is the new White
$color-gray: $gray; // Text gray
$color-grey: $color-gray; // spelling
$color-light-gray: $light-gray; // Footer Background
$color-light-grey: $color-light-gray; // spelling
$color-lighter-gray: #e5e5e5 !default;
$color-dark-gray: #969696 !default;
$color-orange: $orange; // Callout Color
$color-red: $red; // Not part of new design direction, left from checkout
$color-light-brown: $light-brown; // Side Bar
$color-light-pink: #fcf8f5 !default; //light pink for popup overlay BG
$color-gray-border: #646464 !default;

$color-dark-orange: $dark-orange;
$color-purple: $purple;
$color-light-blue: $light-blue;
$color-fb-blue: rgba(59, 89, 152, 0.88);

// extra colors from psds
$color-white--transparent: rgba(251, 248, 246, 0.5); // Off white transparent
$color-active-white: rgba(255, 255, 255, 0.4);
$color-gray-white: rgba(232, 232, 232, 0.3);
$color-white-shadow: rgba(255, 255, 255, 0.5);
$color-overlay--light-dark: rgba(0, 0, 0, 0.7);
$color-overlay--darker: rgba(14, 16, 16, 0.6);
$color-tile--transparent: rgba(251, 248, 246, 0.2);
$color-red-transparent: rgba(220, 53, 48, 0.05);

// Use for common overlay background styling
$color-overlay--dark: rgba(0, 0, 0, 0.8);
$color-overlay--light: rgba(0, 0, 0, 0.3);
$color-overlay--green: rgba(109, 132, 104, 0.7);

// Functional color variables
// Always try using these first
$color-text: $color-brown;
$color-link: $color-brown;
$color-link-hover: $color-gray;
$color-link-light: $color-gray;
$color-link-light-hover: darken($color-gray, 50%);
$color-error: $color-red;
$color-error-background: $color-lightest-red;
$color-bopis-error: $color-dark-red;

// Loyalty / Rewards Program
$color-pure-privilege: #6c9cd2;
$color-dark-blue: #8da7c2;

// @todo can we remove? or adjust to use !default? do we need to?
$color-header-white: #f6f6f6;
$color-light-grey-text: #a6a6a6;
$color-brown-bg: #a2958b;

// one trust cookie color.
$color-darkest-green: #9fa617;
$color-pie-background: #e3e2e0;
$color-pie-text: #f77357;
$color-pp-background: #efece5;
$color-brown-tier: #63514f;
$color-grey-tier: #bdbdbd;

// New colors for $styles20 Styleguide updates
/*
White (#FFFFFF) for Site background. Reversed out text, buttons, or icons on dark backgrounds
Almost Black (#120E02) for text, utility nav and footer background, button fill and outline, icons
Light Linen (#F4F2EE) for module, banner and section backgrounds. Use very sparingly, default to white background
Belgian Linen (#EDEAE4) for keylines, outlines, and rollover states (as needed)
Dark Linen (#E6E3DD) for accents or rollover states for Belgian linen or Light linen modules (as needed)

USE SPARINGLY
Green 1 (#007041) for limited use for button fills or interface elements
Green 2 (#009F4D) for star ratings
Green 3 (#154734) for accents or rollover states for Green 1 and Green 2

Default font will remain: Meta Office Pro
Default Dark Hex Value will update from current mink/brown to Almost Black (Hex Value #120E02)
Default Light Hex Value will update from cream to White (Hex Value #FFFFFF)
Update Global Nav background to White (Hex Value #FFFFFF) with Almost Black text (Hex Value #120E02)
Update top offer banner to Almost Black background & White text
Update ratings & reviews stars to appear with new accent color (Hex Value #88AE2E)
Button updates (page 3) of the style guide including default button fills & rollover states.
*/
$color-off-black: #120e02 !default;
$color-light-linen: #f4f2ee !default;
$color-belgian-linen: #edeae4 !default;
$color-dark-linen: #e6e3dd !default;
$color-green-1: #007041 !default;
$color-green-2: #009f4d !default;
$color-green-3: #154734 !default;
$color-almost-gray: #d2d2d2 !default;
$color-gray-medium: #f3f3f3 !default;
$color-dark-red: #ff0000 !default;
$color-lighter-blue: #5e91ec !default;
$color-lighter-gray-1: #eeebe9 !default;

// Hair finder colors
$color-galliano: #cca221;
$color-sandwisp: #e1cc8a;
$color-narvik: #f7f6f3;

$color-pink-light: #fff0e9;
$color-red-light: #d1575b;
$color-gray-light: #f3eee6;
$color-orange-light: #fff0e9;
$color-blue-dark: #0563c1;
$color-pink-dark: #954f72;
$color-yellow-dark: #ffc000;
$color-blue-thin: #0101d2;

$color-dark-silver: #b8b8b8 !default;
$color-bright-red: #dc3530;
$color-light-white: #fbfbfb;
$color-light-yellow: #f1af38;
$color-light-green: #70b000;
$color-darkest-green: #1c4220;
$color-light-black: #212121;
$color-dark-brown: #808080;
$color-dark-grayish-orange: #9b978e;
$color-gray-thin: #e0e0e0;
$color-gray-lighter: #cccccc;
$color-medium-gray2: #4b4b4b !default;
$color-dark-black: rgba(0, 0, 0, 0.88);
$color-dark-black-4opacity: rgba(0, 0, 0, 0.4);
$color-dark-black-6opacity: rgba(0, 0, 0, 0.6);
$color-dark-black-87opacity: rgba(0, 0, 0, 0.87);
$color-overlay-grey: rgba(18, 14, 0, 0.6);
$color-overlay-dark-gray: rgba(18, 14, 2, 0.6);

// 2022 Global Nav and Footer Styleguide Updates
$color-footer-background: $color-off-black;

// LUX
$color-black-light: #191919;
$color-dark-navy: #040a2b;
$color-gray-darker: #666;
$lux-error-opacity-05: rgba($color-bright-red, 0.05);
$color-focus: #2a6496 !default;
$color-navy-blue: #0075db;
$color--success: #458745 !default;
$color-pink-swan: #b3b3b3;
$color-warm-grey: #777777;
$color-nobel: #999999;
$color-gray-bg: #f5f5f5;
$color-grey-light: #eff1f0;
$color-grey-dark: #525252 !default;
$color--fill-icon-success: #4d5b31;
$color--borders-border-divider: #d4d4d4;
$color--border-divider: #d7d2cb;
$color--textColor-secondary: #656565;
$color--border-button-primary-DEFAULT: #101820;
$color--border-error: #973030;
$color--bg-information: #eff6ff;
$color--textColor-information: #2563eb;

// Hair quiz
$color-beige: rgba(229, 211, 192, 0.95);

//Tmo page
$color-tmo-gray: #9e9e9e;
$color-tmo-red: #a80000;
$color-tmo-error: #bc3c3c;
$color-tmo-light-gray: #737373;
$color-tmo-light-green: #f0fdf4;

//UGC
$color-medium-gray3: #4d5866;

//Search modernized design
$color-red-dark: #bc3c3c !default;
